import { Component, Input, OnInit } from '@angular/core';
import { ICustomField } from '@proxyclick/data-model';
import {
  CardTypesCardTypeV1,
  DivisionsDivisionV1,
  PersonalDataDefinitionsPersonalDataDefinitionV1,
} from '@proxyclick/extender-server-gallagher-client';
import { v4 as uuidv4 } from 'uuid';
import { CompaniesService } from '~/services/companies.service';
import { ExtenderService } from '~/services/extender.service';
import { IOption } from '~/shared/components/input/radio/radio.component';
import { fetchAll } from '~/utils/utils';
import { FastPaceSetupCompanyState } from '../../fastpace-setup-company.state';

@Component({
  selector: 'app-fastpace-company-setup-extenders-gallagher',
  templateUrl: './gallagher-setup.component.html',
})
export class GallagherSetupComponent implements OnInit {
  @Input() setupState: FastPaceSetupCompanyState;
  constructor(private companyService: CompaniesService, private extenderService: ExtenderService) {}
  async ngOnInit(): Promise<void> {
    this.customFields = await this.companyService.getCustomFields(this.setupState.company.id).toPromise();
    this.setCustomFieldOptions();

    const personalDataDefinitions = await fetchAll<PersonalDataDefinitionsPersonalDataDefinitionV1>(
      async page =>
        (
          await this.extenderService.gallagher.personalDataDefinitions.list(this.setupState.extender.extenderId, {
            requestTop: 100,
            requestSkip: (page - 1) * 100,
            requestTracingId: uuidv4(),
            requestFields: [],
            requestName: '',
          })
        ).personalDataDefinitions
    );

    this.gallagherCustomFields = personalDataDefinitions.map(x => x.name);
    this.setGallagherCustomFieldOptions();
    this.selectedGallagherCustomFieldOption = this.gallagherCustomFieldOptions[0].value;

    this.divisions = await fetchAll<DivisionsDivisionV1>(
      async page =>
        (
          await this.extenderService.gallagher.divisions.list(this.setupState.extender.extenderId, {
            requestTop: 100,
            requestSkip: (page - 1) * 100,
            requestTracingId: uuidv4(),
            requestFields: [],
            requestName: '',
          })
        ).divisions
    );
    this.setDivisionsOptions();

    this.cardTypes = (
      await this.extenderService.gallagher.cardTypes.list(this.setupState.extender.extenderId, {
        requestTracingId: uuidv4(),
      })
    ).cardTypes;
  }

  divisions: DivisionsDivisionV1[] = [];
  divisionsOptions: DivisionsDivisionV1[] = [];
  selectedDivisionOption: DivisionsDivisionV1 | null = null;

  mapCustomFields: boolean = false;

  customFields: ICustomField[] = [];
  customFieldOptions: ICustomField[] = [];
  customFieldOption: ICustomField | null = null;

  gallagherCustomFields: string[] = [];
  gallagherCustomFieldOptions: IOption[] = [];
  selectedGallagherCustomFieldOption: string | undefined = '';

  cardTypes: CardTypesCardTypeV1[] = [];

  switchEnableCustomFieldMapping() {
    if (this.mapCustomFields === false) {
      this.setupState.gallagherCustomFieldMappings = [];
      this.setCustomFieldOptions();
      this.setGallagherCustomFieldOptions();
    }
  }

  gallagherDivisionOptionChanged() {
    this.setupState.gallagherDivision = this.selectedDivisionOption;
  }

  setDivisionsOptions() {
    this.divisionsOptions = this.divisions;
    if (this.divisionsOptions.length > 0) {
      this.selectedDivisionOption = this.divisionsOptions[0];
    } else {
      this.selectedDivisionOption = null;
    }
  }

  setGallagherCustomFieldOptions() {
    const options = this.gallagherCustomFields
      .filter(c => {
        //Filter out custom fields already mapped
        return this.setupState.gallagherCustomFieldMappings.filter(a => a.fieldName === c).length === 0;
      })
      .map(c => {
        return { label: c, value: c };
      });

    this.gallagherCustomFieldOptions = options;
    if (this.gallagherCustomFieldOptions.length > 0) {
      this.selectedGallagherCustomFieldOption = this.gallagherCustomFieldOptions[0].value;
    } else {
      this.selectedGallagherCustomFieldOption = undefined;
    }
  }

  setCustomFieldOptions() {
    const options = this.customFields.filter(c => {
      //Filter out custom fields already mapped
      return this.setupState.gallagherCustomFieldMappings.filter(a => a.customField.id === c.id).length === 0;
    });

    this.customFieldOptions = options;
    if (this.customFieldOptions.length > 0) {
      this.customFieldOption = this.customFieldOptions[0];
    } else {
      this.customFieldOption = null;
    }
  }

  canAddMapping() {
    return (
      this.customFieldOption &&
      this.selectedGallagherCustomFieldOption &&
      this.setupState.gallagherCustomFieldMappings.filter(
        c => c.fieldName === this.selectedGallagherCustomFieldOption || c.customField.id === this.customFieldOption.id
      ).length === 0
    );
  }

  addMapping() {
    if (!this.canAddMapping()) {
      return;
    }
    this.setupState.gallagherCustomFieldMappings.push({
      customField: this.customFieldOption,
      fieldName: this.selectedGallagherCustomFieldOption,
    });
    this.setCustomFieldOptions();
    this.setGallagherCustomFieldOptions();
  }

  removeMapping(id: number) {
    this.setupState.gallagherCustomFieldMappings = this.setupState.gallagherCustomFieldMappings.filter(
      c => c.customField.id !== id
    );
    this.setCustomFieldOptions();
    this.setGallagherCustomFieldOptions();
  }

  canGoNext() {
    if (
      (this.mapCustomFields && this.setupState.gallagherCustomFieldMappings.length < 1) ||
      (this.divisions.length > 1 && this.setupState.gallagherDivision == null)
    ) {
      return false;
    }
    return true;
  }
}
