import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { IApplication } from '@proxyclick/data-model';
import { filter, map } from 'rxjs/operators';
import { IAppState } from '~/store/app-state';
import { ApplicationActions } from '~/store/applications/applications.reducer';
import { ActivatedRoute } from '../../../../../../node_modules/@angular/router';

@Component({
  templateUrl: './application-details.html',
})
export class ApplicationDetailsPageComponent implements OnInit {
  application$ = this.store.select('application', 'value');
  loading$ = this.store.select('application', 'loading');
  breadcrumbs$ = this.application$.pipe(
    filter<IApplication>(Boolean),
    map(application => [
      { label: 'Applications', url: '..' },
      {
        label: application.name ? application.name : 'new',
        url: application.id ? `/applications/${application.id}` : '/applications/new',
      },
    ])
  );

  constructor(private store: Store<IAppState>, private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.store.dispatch(ApplicationActions.Load(params.id));
    });
  }
}
